"use client";

import React, { useState } from "react";
import AnimatedBody from "../animations/AnimatedBody.tsx";
import ContactFormModal from "./ContactFormModal.tsx";

const ContactForm: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <button
        onClick={openModal}
        aria-label="Send me a message"
        className="mt-1 w-[147px] flex-1 underline underline-offset-2 hover:no-underline sm:mt-2 sm:w-[147px] md:mt-3 md:w-[170px] lg:mt-4"
      >
        <AnimatedBody text="Send me a message" className="" />
      </button>
      <ContactFormModal isOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
};

export default ContactForm;
