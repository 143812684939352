import {
  SiScikitlearn,
  SiTensorflow,
  SiR,
  SiPython,
  SiMicrosoftexcel,
  SiMysql,
  SiPowershell,
  SiGnubash,
} from "react-icons/si";
import { VscSymbolMethod } from "react-icons/vsc";
import { IconType } from "react-icons";

export type ProjectProps = {
  id: number;
  name: string;
  description: string;
  technologies: IconType[];
  techNames: string[];
  techLinks: string[];
  github: string;
  demo: string;
  image: string;
  available: boolean;
};

export const projects = [
  {
    id: 0,
    name: "Sovereign risk in the era of climate transition",
    description: "ML & DL modelisation to predict sovereign.",
    technologies: [SiPython, SiScikitlearn, SiTensorflow],
    techNames: ["Python", "Sickit-learn", "Tensorflow"],
    techLinks: [
      "https://www.python.org/",
      "https://scikit-learn.org/stable/",
      "https://www.tensorflow.org/?hl=fr",
    ],
    github: "https://github.com/omarbenfeddoul",
    demo: "https://github.com/omarbenfeddoul",
    image: "/projects/dssp.png",
    available: true,
  },
  {
    id: 1,
    name: "Aesset Portfolio Valuation Tool",
    description: "Calculation tool for valuation.",
    technologies: [SiMicrosoftexcel, VscSymbolMethod, SiR],
    techNames: ["Excel", "VBA", "R"],
    techLinks: [
      "https://www.microsoft.com/en-us/microsoft-365/excel",
      "https://docs.microsoft.com/en-us/office/vba/library-reference/concepts/getting-started-with-vba-in-office",
      "https://www.r-project.org/",
    ],
    github: "https://github.com/omarbenfeddoul",
    demo: "https://github.com/omarbenfeddoul",
    image: "/projects/valo-tool.png",
    available: true,
  },
  {
    id: 2,
    name: "Actuarial Reporting Dashboard",
    description: "Interactive dashboard for insurance data visualization.",
    technologies: [SiPython, SiMysql, SiPowershell, SiGnubash],
    techNames: ["Python", "MySQL", "Powershell", "Bash"],
    techLinks: [
      "https://www.r-project.org/",
      "https://www.mysql.com/",
      // TODO update those links ⬇️
      "https://learn.microsoft.com/fr-fr/powershell/scripting/overview?view=powershell-7.4",
      "https://doc.ubuntu-fr.org/bash",
    ],
    github: "https://github.com/omarbenfeddoul",
    demo: "https://github.com/omarbenfeddoul",
    image: "/projects/dssp2.png",
    available: true,
  },
];
