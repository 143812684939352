import React from "react";
import "../animations/animate.css";
import AnimatedBody from "../animations/AnimatedBody.tsx";
import AnimatedTitle from "../animations/AnimatedTitle.tsx";
import {
  SiTensorflow,
  SiPostgresql,
  SiMysql,
  SiJavascript,
  SiNextdotjs,
  SiReact,
  SiTypescript,
  SiScikitlearn,
  SiVisualbasic,
  SiPython,
  SiR,
  SiTableau,
  SiPowerbi,
  SiMicrosoftexcel,
} from "react-icons/si";
import { FaCalculator, FaChartLine, FaFileDownload } from "react-icons/fa";
import AnimatedTools from "../animations/AnimatedTools.tsx";

const Tools = () => {
  const handleDownloadResume = () => {
    const fileName = "CV_Omar.pdf";
    const filePath = `/${fileName}`; // Using a relative path

    fetch(filePath)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
        alert("Failed to download the file. Please try again later.");
      });
  };
  return (
    <section
      className="relative z-10 w-full items-center justify-center overflow-hidden bg-[#0E1016] bg-cover bg-center pt-16 pb-36 md:pt-20 md:pb-44 lg:pt-20 lg:pb-56"
      id="tools"
    >
      <div className="mx-auto flex w-[90%] flex-col items-center justify-center lg:max-w-[1212.8px]">
        <AnimatedTitle
          text={"TECHNICAL SKILLS."}
          className={
            "mb-10 text-left text-[40px] font-bold leading-[0.9em] tracking-tighter text-[#e4ded7] sm:text-[45px] md:mb-16 md:text-[60px] lg:text-[80px]"
          }
          wordSpace={"mr-[14px]"}
          charSpace={"mr-[0.001em]"}
        />

        <div className="mx-auto w-[100%] lg:max-w-[1200px] justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="mb-10 flex w-[100%] flex-col gap-4 text-[18px] font-bold leading-relaxed tracking-wide text-[#e4ded7] md:mb-16 md:gap-6 md:text-[40px] md:leading-relaxed lg:mb-16">
              <AnimatedBody delay={0.2} text="Frontend" />
              <div>
                <AnimatedTools
                  className="grid grid-cols-4 gap-4"
                  delay={0.2}
                  stepSize={0.1}
                  iconSize={50}
                >
                  <SiTypescript size={50} />
                  <SiJavascript size={50} />
                  <SiReact size={50} />
                  <SiNextdotjs size={50} />
                </AnimatedTools>
              </div>
            </div>
            <div className="mb-10 flex w-[100%] flex-col gap-4 text-[18px] font-bold leading-relaxed tracking-wide text-[#e4ded7] md:mb-16 md:gap-6 md:text-[40px] md:leading-relaxed lg:mb-16">
              <AnimatedBody delay={0.3} text="Backend" />
              <div>
                <AnimatedTools
                  className="grid grid-cols-4 gap-4"
                  delay={0.3}
                  stepSize={0.1}
                  iconSize={50}
                >
                  <SiPython size={50} />
                  <SiR size={50} />
                  <SiPostgresql size={50} />
                  <SiMysql size={50} />
                </AnimatedTools>
              </div>
            </div>
            <div className="mb-10 flex w-[100%] flex-col gap-4 text-[18px] font-bold leading-relaxed tracking-wide text-[#e4ded7] md:mb-16 md:gap-6 md:text-[40px] md:leading-relaxed lg:mb-16">
              <AnimatedBody delay={0.4} text="Data Visualization" />
              <div>
                <AnimatedTools
                  className="grid grid-cols-4 gap-4"
                  delay={0.4}
                  stepSize={0.1}
                  iconSize={50}
                >
                  <SiTableau size={50} />
                  <SiPowerbi size={50} />
                  <FaChartLine size={50} />
                  <SiMicrosoftexcel size={50} />
                </AnimatedTools>
              </div>
            </div>
            <div className="mb-10 flex w-[100%] flex-col gap-4 text-[18px] font-bold leading-relaxed tracking-wide text-[#e4ded7] md:mb-16 md:gap-6 md:text-[40px] md:leading-relaxed lg:mb-16">
              <AnimatedBody delay={0.5} text="Modeling & ML" />
              <div>
                <AnimatedTools
                  className="grid grid-cols-4 gap-4"
                  delay={0.5}
                  stepSize={0.1}
                  iconSize={50}
                >
                  <SiVisualbasic size={50} />
                  <FaCalculator size={50} />
                  <SiScikitlearn size={50} />
                  <SiTensorflow size={50} />
                </AnimatedTools>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={handleDownloadResume}
          className="mt-12 flex items-center gap-2 rounded-full bg-[#e4ded7] px-7 py-3 text-lg font-bold text-[#0E1016] transition-all hover:scale-105 hover:bg-white"
        >
          <FaFileDownload size={20} />
          Download Resume
        </button>
      </div>
    </section>
  );
};

export default Tools;
